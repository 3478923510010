import React from "react"
import Layout from "../components/layout"

import contactStyles from "../styles/contact.module.scss"

const contactForm = () => {
  return (
    <Layout>
      <h1>Contact Us</h1>
      <div className={contactStyles.infoText}>
        <h3>
          Use the form below to get in touch, if you'd rather send us an email
          you can give us a shout at crazycrittersexperience@outlook.com
        </h3>
        <h3>Want to speak to us directly give Luke a call on 07546912001</h3>
      </div>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className={contactStyles.contactContainer}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div
          className={[
            contactStyles.field,
            contactStyles.half,
            contactStyles.first,
          ].join(" ")}
        >
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className={[contactStyles.field, contactStyles.half].join(" ")}>
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" />
        </div>
        <div className={contactStyles.field}>
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="4" />
        </div>
        <div className="actions">
          <input type="submit" value="Send Message" className="special" />
        </div>
      </form>
    </Layout>
  )
}

export default contactForm
